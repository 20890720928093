<template>
  <div class="height_100">
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="待接单" name="first">
        <div class="flex" style="align-items: center;">
              <div style="font-size:14px;margin-right: 50px;">类型<i style="color:#EC3D3D">*</i></div>
              <el-select v-model="ruleForm.region" placeholder="请选择所属分类">
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
          <div class="flex marl_30" style="align-items: center;">
            <div
              style="
                width: 100px;
                line-height: 20px;
                position: relative;
                font-size: 18px;
                margin-right: 50px;
              "
            >
              关键词搜索
            </div>
            <div class="flex" @click="serach()" style="border: 1px solid #cccc;margin-right: 50px; ">
              <input  @keyup.enter="serach"
                type="text"
                v-model="search"
                placeholder="请输入内容"
                style="
                  width: 200px;
                  height: 40px;
                  border: none;
                  outline:none;
                  border-radius: 5px;
                  padding: 10px;
                  
                "

              />
              <!-- <el-input placeholder="请输入内容"></el-input> -->
              <i
                class="el-icon-search"

                style="font-size: 20px; line-height: 40px ;margin-right: 10px; "
              ></i>
            </div>

            <div
              @click="addproduct()"
              style="
                width: 122px;
                height: 40px;
                background: #ff6618;
                border-radius: 6px;
                line-height: 40px;
                text-align: center;
              "
              class="fff"
            >
              发布诉求
            </div>
          </div>
        </div>

        <el-table :data="TableData" style="width: 100%">
          <el-table-column
            prop="ID"
            label="ID"
            min-width="125"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="order_name"
            label="标题"
            min-width="135"
            align="center"
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="type_name"
            label="类型"
            min-width="125"
          ></el-table-column>

          <el-table-column
            prop="appeal"
            label="诉求内容"
            min-width="125"
            align="center"
          >
            <template slot-scope="scope">
              <el-button type="text" size="small" style="color: #ff6618"   @click="lookmessage(scope.row)">查看详情</el-button
             >
            </template>
          </el-table-column>

          <el-table-column
            prop="create_time"
            label="发布时间"
            min-width="165"
            align="center"
          >
          </el-table-column>

          <el-table-column
            prop="content"
            label="操作"
            min-width="165"
            align="center"
          >
            <template slot-scope="scope">
              <el-button type="text" size="small" style="color: #fb1010" @click="confirm(scope.row,4)"
                >取消订单</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </el-tab-pane>
      <el-tab-pane label="服务中" name="second">
        <!-- <div class="flex">
          <el-form :model="ruleForm" label-width="100px" class="demo-ruleForm">
            <el-form-item label="类型" prop="region">
              <el-select v-model="ruleForm.region" placeholder="请选择所属分类">
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-form>
          <div class="flex marl_30">
            <div
              style="
                width: 100px;
                line-height: 40px;
                position: relative;
                font-size: 14px;
              "
            >
              关键词搜索
            </div>
            <div class="flex">
              <input
                type="text"
                v-model="search"
                placeholder="请输入内容"
                style="
                  width: 200px;
                  height: 40px;
                  border: 1px solid #cccc;
                  border-radius: 5px;
                  padding: 10px;
                "
              />
              <!-- <el-input placeholder="请输入内容"></el-input>
              <i
                class="el-icon-search"
                style="font-size: 30px; line-height: 40px"
              ></i>
            </div>
            <div
              @click="addproduct()"
              style="
                width: 122px;
                height: 40px;
                background: #ff6618;
                border-radius: 6px;
                line-height: 40px;
                text-align: center;
              "
              class="fff"
            >
              发布诉求
            </div>
          </div>
        </div> -->


        <div class="flex" style="align-items: center;">
              <div style="font-size:14px;margin-right: 50px;">类型<i style="color:#EC3D3D">*</i></div>
              <el-select v-model="ruleForm.region" placeholder="请选择所属分类">
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
          <div class="flex marl_30" style="align-items: center;">
            <div 
              style="
                width: 100px;
                line-height: 20px;
                position: relative;
                font-size: 18px;
                margin-right: 50px;
              "
            >
              关键词搜索
            </div>
            <div class="flex" @click="serach()" style="border: 1px solid #cccc;margin-right: 50px; ">
              <input @keyup.enter="serach"
                type="text"
                v-model="search"
                placeholder="请输入内容"
                style="
                  width: 200px;
                  height: 40px;
                  border: none;
                  border-radius: 5px;
                  padding: 10px;
                  outline:none;
                "
              />
              <!-- <el-input placeholder="请输入内容"></el-input> -->
              <i
                class="el-icon-search"

                style="font-size: 20px; line-height: 40px ;margin-right: 10px; "
              ></i>
            </div>

            <div
              @click="addproduct()"
              style="
                width: 122px;
                height: 40px;
                background: #ff6618;
                border-radius: 6px;
                line-height: 40px;
                text-align: center;
              "
              class="fff"
            >
              发布诉求
            </div>
          </div>
        </div>



        <el-table :data="TableData" style="width: 100%">
          <el-table-column
            prop="ID"
            label="ID"
            min-width="125"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="order_name"
            label="标题"
            min-width="135"
            align="center"
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="type_name"
            label="类型"
            min-width="125"
          ></el-table-column>

          <el-table-column
            prop="appeal"
            label="诉求内容"
            min-width="125"
            align="center"
          >
            <template slot-scope="scope">
              <el-button type="text" size="small" style="color: #ff6618" @click="lookmessage(scope.row)"
                >查看详情</el-button
              >
            </template>
          </el-table-column>

          <el-table-column
            prop="create_time"
            label="发布时间"
            min-width="165"
            align="center"
          >
          </el-table-column>

          <el-table-column
            prop="content"
            label="操作"
            min-width="165"
            align="center"
          >
            <template slot-scope="scope">
              <el-button type="text" size="small" style="color: #c065e7"
              @click="lookattache(scope.row)"
                >查看服务专员信息</el-button
              >
              <el-button
                type="text"
                size="small"
                style="color: #269df8"
                @click="recommendProducts(scope.row)"
                >推荐产品列表</el-button
              >
              <el-button
                type="text"
                size="small"
                style="color: #ff6618"
                @click="confirm(scope.row,3)"
                >确定完成</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <el-dialog
          title="推荐产品列表"
          :visible.sync="recommendlist"
          width="1464px"
        >
          <el-table :data="LogData" style="width: 100%">
            <el-table-column
              prop="ID"
              label="ID"
              min-width="100"
              align="center"
            ></el-table-column>
            <el-table-column
              prop="productname"
              label="产品名称"
              min-width="100"
              align="center"
            >
            </el-table-column>
            <el-table-column
              align="center"
              prop="productype"
              label="产品类型"
              min-width="100"
            ></el-table-column>
            <el-table-column
              prop="pattern"
              label="服务模式"
              min-width="100"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="mechanism_name"
              label="机构名称"
              min-width="100"
              align="center"
            >
            </el-table-column>

            <el-table-column
              prop="spend"
              label="费用"
              min-width="100"
              align="center"
            >
            </el-table-column>

            <el-table-column
              prop="name"
              label="联系人"
              min-width="100"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="phone"
              label="联系方式"
              min-width="100"
              align="center"
            >
            </el-table-column>

            <el-table-column
              prop="Email"
              label="邮箱"
              min-width="125"
              align="center"
            >
            </el-table-column>

            <el-table-column
              prop="content"
              label="操作"
              min-width="125"
              align="center"
            >
              <template slot-scope="scope">
                <el-button
                  type="text"
                  size="small"
                  style="color: #ff6618"
                  @click="logrecommend(scope.row)"
                  >立即申请服务</el-button
                >
              </template>
            </el-table-column>
          </el-table>

          <el-pagination
          v-if="LogData.length != 0 "
            @size-change="loghandleSizeChange"
            @current-change="loghandleCurrentChange"
            :current-page="logpaginations.page_index"
            :page-sizes="logpaginations.page_sizes"
            :page-size="logpaginations.page_size"
            :layout="logpaginations.layout"
            :total="logpaginations.total"
          >
          </el-pagination>
        </el-dialog>
      </el-tab-pane>
      <el-tab-pane label="已完成" name="third">
        <!-- <div class="flex">
          <el-form :model="ruleForm" label-width="100px" class="demo-ruleForm">
            <el-form-item label="类型" prop="region">
              <el-select v-model="ruleForm.region" placeholder="请选择所属分类">
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-form>
          <div class="flex marl_30">
            <div
              style="
                width: 100px;
                line-height: 40px;
                position: relative;
                font-size: 14px;
              "
            >
              关键词搜索
            </div>
            <div class="flex">
              <input
                type="text"
                v-model="search"
                placeholder="请输入内容"
                style="
                  width: 200px;
                  height: 40px;
                  border: 1px solid #cccc;
                  border-radius: 5px;
                  padding: 10px;
                "
              />
              <!-- <el-input placeholder="请输入内容"></el-input> 
              <i
                class="el-icon-search"
                style="font-size: 30px; line-height: 40px"
              ></i>
            </div>
          </div>
        </div> -->

        
        <div class="flex" style="align-items: center;">
              <div style="font-size:14px;margin-right: 50px;">类型<i style="color:#EC3D3D">*</i></div>
              <el-select v-model="ruleForm.region" placeholder="请选择所属分类">
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
          <div class="flex marl_30" style="align-items: center;">
            <div
              style="
                width: 100px;
                line-height: 20px;
                position: relative;
                font-size: 18px;
                margin-right: 50px;
              "
            >
              关键词搜索
            </div>
            <div class="flex" @click="serach()" style="border: 1px solid #cccc;margin-right: 50px; ">
              <input @keyup.enter="serach"
                type="text"
                v-model="search"
                placeholder="请输入内容"
                style="
                  width: 200px;
                  height: 40px;
                  border: none;
                  border-radius: 5px;
                  padding: 10px;
                  outline:none;
                "
              />
              <!-- <el-input placeholder="请输入内容"></el-input> -->
              <i
                class="el-icon-search"

                style="font-size: 20px; line-height: 40px ;margin-right: 10px; "
              ></i>
            </div>

            
          </div>
        </div>

        <el-table :data="TableData" style="width: 100%">
          <el-table-column
            prop="ID"
            label="ID"
            min-width="125"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="order_name"
            label="标题"
            min-width="135"
            align="center"
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="type_name"
            label="类型"
            min-width="125"
          ></el-table-column>

          <el-table-column
            prop="appeal"
            label="诉求内容"
            min-width="125"
            align="center"
          >
            <template slot-scope="scope">
              <el-button type="text" size="small" style="color: #ff6618" @click="lookmessage(scope.row)"
                >查看详情</el-button
              >
            </template>
          </el-table-column>

          <el-table-column
            prop="create_time"
            label="发布时间"
            min-width="165"
            align="center"
          >
          </el-table-column>

          <el-table-column
            prop="content"
            label="操作"
            min-width="165"
            align="center"
          >
            <template>
              <div>无</div>
            </template>
          </el-table-column>
        </el-table>
      </el-tab-pane>
      <el-tab-pane label="已取消" name="fourth">
        <!-- <div class="flex">
          <el-form :model="ruleForm" label-width="100px" class="demo-ruleForm">
            <el-form-item label="类型" prop="region">
              <el-select v-model="ruleForm.region" placeholder="请选择所属分类">
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-form>
          <div class="flex marl_30">
            <div
              style="
                width: 100px;
                line-height: 40px;
                position: relative;
                font-size: 14px;
              "
            >
              关键词搜索
            </div>
            <div class="flex">
              <div style="border: 1px solid #fafafa">
                <input
                  type="text"
                  v-model="search"
                  placeholder="请输入内容"
                  style="
                    width: 200px;
                    height: 40px;
                    border: 1px solid transparent;
                    border-radius: 5px;
                    padding: 10px;
                  "
                />
                <i
                  class="el-icon-search"
                  style="font-size: 30px; line-height: 40px"
                ></i>
              </div>
            </div>
          </div>
        </div> -->

        
        <div class="flex" style="align-items: center;">
              <div style="font-size:14px;margin-right: 50px;">类型<i style="color:#EC3D3D">*</i></div>
              <el-select v-model="ruleForm.region" placeholder="请选择所属分类">
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
          <div class="flex marl_30" style="align-items: center;">
            <div
              style="
                width: 100px;
                line-height: 20px;
                position: relative;
                font-size: 18px;
                margin-right: 50px;
              "
            >
              关键词搜索
            </div>
            <div class="flex" @click="serach()" style="border: 1px solid #cccc;margin-right: 50px; ">
              <input @keyup.enter="serach"
                type="text" 
                v-model="search"
                placeholder="请输入内容"
                style="
                  width: 200px;
                  height: 40px;
                  border: none;
                  border-radius: 5px;
                  padding: 10px;
                  outline:none;
                "
              />
              <!-- <el-input placeholder="请输入内容"></el-input> -->
              <i
                class="el-icon-search"

                style="font-size: 20px; line-height: 40px ;margin-right: 10px; "
              ></i>
            </div>

            
          </div>
        </div>

        <el-table :data="TableData" style="width: 100%">
          <el-table-column
            prop="ID"
            label="ID"
            min-width="125"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="order_name"
            label="标题"
            min-width="135"
            align="center"
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="type_name"
            label="类型"
            min-width="125"
          ></el-table-column>

          <el-table-column
            prop="appeal"
            label="诉求内容"
            min-width="125"
            align="center"
          >
            <template slot-scope="scope"> 
              <el-button type="text" size="small" style="color: #ff6618" @click="lookmessage(scope.row)"
                >查看详情</el-button
              >
            </template>
          </el-table-column>

          <el-table-column
            prop="create_time"
            label="发布时间"
            min-width="165"
            align="center"
          >
          </el-table-column>

          <el-table-column
            prop="content"
            label="操作"
            min-width="165"
            align="center"
          >
            <template>
              <div>无</div>
            </template>
          </el-table-column>
        </el-table>
      </el-tab-pane>
    </el-tabs>
   
    <el-dialog title="发布诉求" :visible.sync="product" width="600px" center>
      <el-form :model="subappeal" :rules="rules" ref="subappeal">
        <el-form-item label="诉求标题" prop="title">
          <el-input
            placeholder="请输入诉求标题"
            v-model="subappeal.title"
          ></el-input>
        </el-form-item>
        <el-form-item label="诉求类型" prop="region">
          <el-select v-model="subappeal.region" placeholder="请选择所属分类">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="诉求内容" prop="content">
          <el-input
            placeholder="请输入诉求内容"
            type="textarea"
            :rows="6"
            v-model="subappeal.content"
          ></el-input>
        </el-form-item>
      </el-form>
      <div>
        <el-button
          style="
            color: #fefefe;
            background-color: #ff6618;
            width: 137px;
            height: 50px;
            margin-top: 20px;
          "
          @click="sub()"
          type="text"
        >
          确定发布
        </el-button>
        <el-button
          style="
            color: #fefefe;
            background-color: #cfcfcf;
            width: 137px;
            height: 50px;
            margin-top: 20px;
          "
          type="text"
          @click="logcancel()"
        >
          取消
        </el-button>
      </div>

    </el-dialog>

  
    <el-dialog title="查看诉求" :visible.sync="messagelogin" width="600px" center>
      <div class="marb_30">
        <div class="marb_10">诉求标题</div>
        <div >{{message.order_name}}</div>
      </div>
      <div class="marb_30">
        <div class="marb_10">诉求类型</div>
        <div >{{message.type_name}}</div>
      </div>
      <div class="marb_30">
        <div class="marb_10">诉求内容</div>
        <div > {{message.content}}</div>
      </div>

    </el-dialog>

          <el-pagination
          v-if="TableData.length != 0 "
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="paginations.page_index"
            :page-sizes="paginations.page_sizes"
            :page-size="paginations.page_size"
            :layout="paginations.layout"
            :total="paginations.total"
          >
          </el-pagination>
  </div>
</template>

<script>
export default {
  data() {
    return {
      message:{},
      messagelogin:false,//详情弹窗
      total: "",
      activeName: "first",
      logid: "", //服务订单id
      search: "",
      //推荐产品数据
      LogData: [],
      totals: "",
      allLogData: [],
      //诉求
      subappeal: {
        region: "",
        title: "",
        content: "",
        optionss: [],
      },
      //分页
      paginations: {
        page_index: 1, // 当前位于哪页
        total: 10, // 总数
        page_size: 10, // 1页显示多少条
        page_sizes: [10, 20, 50, 90], //每页显示多少条
        layout: "total, sizes, prev, pager, next, jumper", // 翻页属性
      },
      //推荐产品分页
      logpaginations: {
        page_index: 1, // 当前位于哪页
        total: 10, // 总数
        page_size: 10, // 1页显示多少条
        page_sizes: [10, 20, 50, 90], //每页显示多少条
        layout: "total, sizes, prev, pager, next, jumper", // 翻页属性
      },
      ruleForm: { region: "" },
      rules: {
        title: [
          { required: true, message: "请输入诉求标题", trigger: "blur" },
          { required: true, message: "诉求标题不能为空", trigger: "change" },
        ],
        region: [{ required: true, message: "请选择所属分类" }],
        content: [
          { required: true, message: "请输入诉求内容", trigger: "blur" },
          { required: true, message: "诉求内容不能为空", trigger: "change" },
        ],
      },
      options: [],
      TableData: [],
      allTableData: [],
      sta: 1,
      product: false,
      recommendlist: false,

    };
  },
  methods: {
    // userlist() {
    //   let data = {
    //     token: localStorage.eleToken,
    //   };
    //   this.$get("user_lst_d", data).then((res) => {
    //     console.log(res, " res");
    //   });
    // },
    lookattache(item){
      console.log(item,'item')
      this.message = {}
      this.$router.push({
        path: "/commissionerdetails",
        query: { cid: item.commissioner_id },
      });
    },
    //查看详情
    lookmessage(item){
      console.log(item,'item')
      let data = {
        id: item.ID,
        token: localStorage.eleToken,
      };

      this.$get("/order_lst_d", data).then((res) => {
        this.message = res.result
        this.messagelogin = true
        console.log(res, "res");
      });
    },

    //确定完成
    confirm(item,type) {
      let data = {
        id: item.ID,
        token: localStorage.eleToken,
        status: type,
      };

      this.$post("/order_upd ", data).then((res) => {
        console.log(res, "res");
        this.$message({
          message: res.msg,
          type: "success",
          duration: 1500,
        });
        this.postorderlist();
      });
    },
    logrecommend(item) {
      let data = {
        service_product_id: item.ID,
        order_id: this.logid,
        token: localStorage.eleToken,
        page: this.logpaginations.page_index,
        limit: this.logpaginations.page_size,
      };

      this.$post("/product_order_save ", data).then((res) => {
        console.log(res, "res");
        this.$message({
          message: res.msg,
          type: "success",
          duration: 1500,
        });
        this.recommendlist = false;
      });
    },

    beenlogorderlist(item) {
      console.log("789456");
      let data = {
        id: this.logid,
        token: localStorage.eleToken,
        page: item ? item : this.paginations.page_index,
        limit: this.logpaginations.page_size,
      };

      this.$post("/order_product_lst ", data).then((res) => {
        console.log(res, "res");
        this.totals = res.count;
        this.allLogData = res.result;
        this.setlogPaginations();
      });
    },

    recommendProducts(item) {
      this.logid = item.ID;
      this.recommendlist = true;
      this.beenlogorderlist();
    },
    serach() {
      this.postorderlist();
    },
    sub() {
      this.$refs["subappeal"].validate((valid) => {
        if (valid) {
          let data = {
            token: localStorage.eleToken,
            name: this.subappeal.title,
            // status: ,
            content: this.subappeal.content,
            pro_cate_id: this.subappeal.region,
          };
          this.$post("order_add", data).then((res) => {
            console.log(res);
            // this.$message({
            //   message: res.msg,
            //   type: "success",
            //   duration: 1500,
            // });
            this.postorderlist();
            this.product = false;
          });
        }
      });
    },

    postorderlist(item) {
      let data = {
        token: localStorage.eleToken,
        keyword: this.search,
        pro_cate_id: this.ruleForm.region === "" ? "" : this.ruleForm.region,
        status: this.sta,
        page: item ? item : this.paginations.page_index,
        limit: this.paginations.page_size,
      };

      this.$post("order_user_lst", data).then((res) => {
        console.log(res, "res");
        this.allTableData = res.result;
        if (res.count === "") {
          this.paginations.total = 0;
        } else {
          this.paginations.total = res.count;
        }
        console.log(this.paginations.total,'this.paginations.total')
        if (this.allTableData.length != 0) {
          this.TableData = this.allTableData.filter((item, index) => {
            return index < this.paginations.page_size;
          });
        } else {
          this.TableData = [];
        }

      });
    },

    addproduct() {
      this.authentication();
      this.subappeal = {}
      console.log("789456");
    },
    logcancel() {
      this.product = false;
    },

    // 认证
    authentication() {
      let data = {
        token: localStorage.eleToken,
      };
      console.log(data, "data");
      this.$get("/enterprise_user_status", data).then((res) => {
        console.log(res, "res456");
        if (res.status === 1) {
          this.product = true;
        } else if (res.status === 2) {
          // this.$message({
          //   type: "warning",
          //   message: "请先认领",
          // });
          setInterval(() => {
            // location.href = "http://sso.zhenchangkj.com:9000/realname/index";
            // location.href = "http://sso.smeqh.cn:9000/realname/index";
            this.$message({
              type: "info",
              message: "您的账号还未实名认领，请认领通过之后再进行下一步操作",
            });
          }, 2000);
        }
      });
    },

    //获取下拉列表
    getcatelist() {
      let data = { token: localStorage.eleToken };
      this.$get("cate_list", data).then((res) => {
        this.options = res.result.map((item) => {
          return {
            value: item.id,
            label: item.name,
          };
        });
      });
    },

    handleSizeChange(page_size) {
      console.log(page_size, "page_size");
      this.paginations.page_index = 1;
      this.paginations.page_size = page_size;
      this.postorderlist();
      this.TableData = this.allTableData.filter((item, index) => {
        return index < page_size;
      });
    },

    // 分页

    handleClick(tab, event) {
      this.search = ""
      console.log(tab, event, "tab");
      this.sta = Number(tab.index) + 1;
      console.log(this.sta, "this.sta ");
      this.getcatelist();
      this.postorderlist();
    },
    handleCurrentChange(page) {
      console.log(page, "handleCurrentChangepage");
      //获取当前页
      let index = this.paginations.page_size * (page - 1);
      //获取数据总数
      let nums = this.paginations.page_size * page;
      //容器
      let tables = [];
      this.postorderlist(page);
      for (let i = index; i < nums; i++) {
        if (this.allTableData[i]) {
          tables.push(this.allTableData[i]);
        }
        this.TableData = tables;
      }
    },

    // 推荐产品分页
    loghandleCurrentChange(page) {
      console.log(page, "handleCurrentChangepage");
      //获取当前页
      let index = this.logpaginations.page_size * (page - 1);
      //获取数据总数
      let nums = this.logpaginations.page_size * page;
      //容器
      let tables = [];
      this.beenlogorderlist(page);
      for (let i = index; i < nums; i++) {
        if (this.allLogData[i]) {
          tables.push(this.allLogData[i]);
        }
        this.LogData = tables;
      }
    },

    loghandleSizeChange(page_size) {
      console.log(page_size, "page_size");
      this.logpaginations.page_index = 1;
      this.logpaginations.page_size = page_size;
      this.beenlogorderlist();
      this.LogData = this.allLogData.filter((item, index) => {
        return index < page_size;
      });
    },

    //重置分页
    setlogPaginations() {
      // 总页数
      this.logpaginations.total = this.totals;
      this.logpaginations.page_index = 1;
      this.logpaginations.page_size = 10;
      //设置默认的分页数据
      if (this.allLogData.length != 0) {
        this.LogData = this.allLogData.filter((item, index) => {
          return index < this.logpaginations.page_size;
        });
        console.log();
      } else {
        this.LogData = [];
      }
    },
  },
  created() {
    this.getcatelist();
    this.postorderlist();
    // this.userlist();
  },
};
</script>
<style lang="less" scoped>
::v-deep .el-pagination {
  text-align: center;
  margin-top: 20px;
}
//修改菜单颜色
/deep/.el-tabs__item.is-active {
  color: #ff7129 !important;
}
/deep/.el-tabs__active-bar {
  background-color: #ff7129 !important;
}
/deep/.el-tabs__item:hover {
  color: #ff7129 !important;
}
</style>